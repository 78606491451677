import { useState, useEffect } from 'react';

const useOfferTimeRate = (initialMilliseconds, repeat = false) => {
  console.log("initialMilliseconds", initialMilliseconds);
  const [time, setTime] = useState(initialMilliseconds);
  const [isRunning, setIsRunning] = useState(true);


  const calculateTimeLeft = (ms) => {
    if (isNaN(ms) || ms < 0) return { hours: '00', minutes: '00', seconds: '00' }; 

    const hours = Math.floor(ms / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    const seconds = Math.floor((ms % (1000 * 60)) / 1000).toString().padStart(2, '0');

    return {
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(initialMilliseconds));

  useEffect(() => {
    if (isNaN(time) || time <= 0 || !isRunning) return;

    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1000) {
          if (repeat) {
            return initialMilliseconds; 
          } else {
            clearInterval(interval);
            return 0;
          }
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [time, repeat, initialMilliseconds, isRunning]);

  useEffect(() => {
    setTimeLeft(calculateTimeLeft(time));
  }, [time]);

  useEffect(() => {
    if (!isNaN(initialMilliseconds)) {
      setTime(initialMilliseconds);
      setIsRunning(true);
    }
  }, [initialMilliseconds]);

  const stopTimer = () => {
    setIsRunning(false);
  };

  const startTimer = () => {
    setIsRunning(true);
  };

  return { timeLeft, stopTimer, startTimer };
};

export default useOfferTimeRate;
