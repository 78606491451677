import { useState } from "react";
import './style.css';
import {useSelector} from 'react-redux'
import Switch from "react-switch";

export default function AddForm({handleAddLocation , rowData ,  showEditModal}) {
    const language = useSelector((state) => state.language.lang);
    const [formdata , setFormData] = useState({
        city:"",
        country:"",
        location:"",
        name:"",
        phone:"",
        isMain:false,
        user_id:"",
    })

    function handleSubmit(e) {
        e.preventDefault();
        const data_send = {
            ...formdata ,
            isMain : true ?"1" : "0"
        }
       handleAddLocation(data_send);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>{language == "ar" ? "المدينه" : "City"}</label>
                <input type="text" onChange={(e) => setFormData({...formdata , city : e.target.value})}/>
            </div>

            <div className="form-group">
                <label>{language == "ar" ? "الدولة" : "Country"}</label>
                <input type="text" onChange={(e) => setFormData({...formdata , country : e.target.value})}/>
            </div>

            <div className="form-group">
                <label>{language == "ar" ? "العنوان" : "location"}</label>
                <input type="text" onChange={(e) => setFormData({...formdata , location : e.target.value})}/>
            </div>

            <div className="form-group">
                <label>{language == "ar"?"الاسم" : "Name"}</label>
                <input type="text" onChange={(e) => setFormData({...formdata , name:e.target.value })}/>
            </div>

            <div className="form-group">
                <label>{language == "ar"?"رقم الهاتف" :"Phone"}</label>
                <input type="tel" onChange={(e) => setFormData({...formdata , phone : e.target.value})}/>
            </div>

            <div className="form-group">
                <label>{language == "ar" ? "أساسي" : "isMain"}</label>
                <Switch checked={formdata.isMain}
                    onChange={(checked) => setFormData({ ...formdata, isMain: checked })}/>
            </div>

            <button className="btn btn-primary">{language == "ar" ? "إضافة" : "Submit"}</button>
        </form>
    )
}