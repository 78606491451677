// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otheroffers_oage {
  margin-bottom: 100px;
  padding-bottom: 40px !important;
}

.other-offer-content {
  padding: 20px 25px;
}

.otheroffers {
  padding-bottom: 100px !important;
}


.offerPage .offerHeader{
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  color: #62008a !important;
  font-family: 'FLTB';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-top: 52px; */
}

.av_date {
  
}`, "",{"version":3,"sources":["webpack://./src/components/OtherOffers/otheroffers.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC;;;AAGA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;;AAEA","sourcesContent":[".otheroffers_oage {\n  margin-bottom: 100px;\n  padding-bottom: 40px !important;\n}\n\n.other-offer-content {\n  padding: 20px 25px;\n}\n\n.otheroffers {\n  padding-bottom: 100px !important;\n}\n\n\n.offerPage .offerHeader{\n  font-weight: 700;\n  font-size: 18px;\n  margin-bottom: 15px;\n  color: #62008a !important;\n  font-family: 'FLTB';\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  /* margin-top: 52px; */\n}\n\n.av_date {\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
