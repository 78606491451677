import React from 'react';
import './returnitem.css';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
const ReturnItem = ({ item }) => {
  const language = useSelector((state) => state.language.lang);
  const { id, name, from, price, img, pieces, status, resone } = item;
  const navigate = useNavigate();
  return (
    <div
      className="returnitem product productDiv"
      onClick={() => {
        navigate("/returnlog", {
          state: {
            orderdata: item,
          },
        });
      }}
    >
      <div className="top topDiv">
        <div className="des">
          <h5>
            {item?.order?.products && item?.order?.products.length
              ? language == 'ar'
                ? item?.order?.products[0]?.title_ar
                : item?.order?.products[0]?.title
              : null}
          </h5>

          <h5>
            {language == 'ar' ? "رقم المسترجع" : "Return No"}{" "}
            <span style={{ color: '#666' }}>{item.id}</span>
          </h5>
          <div className="rowDiv">
            <span className="grey-text">
              {moment(item?.createdAt).local("en").format("DD/MM/YYYY")}
            </span>
            <span
              style={{
                whiteSpace: "pre-wrap",
                padding: "5px 16px",
                background: "#7f19a8",
                color: "white",
                borderRadius: "30px",
              }}
              className={item.status}
            >
              {language == "ar"
                ? item?.status == "pending"
                  ? "في انتظار رد الموظف"
                  : item?.status == "captain on the way"
                  ? "في الطريق"
                  : item?.status == "underchacking"
                  ? "يتم فحص العنصر"
                  : item?.status == "out for delivery"
                  ? "تم رفض الطلب"
                  : item?.status == "Return Confirmed"
                  ? "تم الاسترجاع بنجاح"
                  : "هناك مشكلة في الطلب"
                : item?.status}
            </span>
            <div style={{ color: "black", fontWeight: 900 }}>
              {(item.price * 1).toFixed(0)}$
            </div>
          </div>
        </div>
      </div>
      <hr style={{ margin: '0px 0px' }} />
      <div className="bottom">
        <h4>{language == 'ar' ? "سبب الرجوع" : "return reason"}</h4>
        <p>{language == "ar" ? item.reason_ar : item.reason}</p>
      </div>
    </div>
  );
};

export default ReturnItem;
