import React from 'react'
import './HeaderV3.css'
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from 'react-redux';

const HeaderV3 = ({title,onPress}) => {
  const language = useSelector((state) => state.language.lang);

  return (
    <div className='head_v3'>
      <div className='back_arrow'>
        <IoIosArrowBack
          onClick={onPress}
        />
      </div>
      <div style={language=="ar"?{textAlign:"right"}:{textAlign:"left"}} className='notification-bar'>
        {language == "ar"? "شريط الاشعارات":"Notification Bar"}
      </div>
    </div>
  )
}

export default HeaderV3
