import React, { useEffect, useState } from "react";
import { FaCircleCheck, FaListUl, FaLocationDot, FaPlus, FaShop, FaUser } from "react-icons/fa6";
import { MdNotificationsNone } from "react-icons/md";
import { LiaStoreAltSolid } from "react-icons/lia";
import { ImLocation } from "react-icons/im";
import { FaHeadset } from "react-icons/fa";
import { BsFillShieldLockFill } from "react-icons/bs";
import { MdLanguage } from "react-icons/md";
import { TbCertificate } from "react-icons/tb";

import { PiSignOut } from "react-icons/pi";

import CurrentPage from "../CustomHooks/CurrentPage/CurrentPage";
import { BsChevronRight } from "react-icons/bs";
// import './profile.css';
import "./newProfile.css";
import base64 from "base-64";

import PagesHeader from "../../PagesHeader/PagesHeader";
import { useNavigate } from "react-router";
import axios from "axios";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { ByeHand } from "../header/svgIcons";
import PagesButtons from "../PagesButtons/PagesButtons";
import lockedImg from '../../assets/Screenshot_2024-08-19_145353-removebg-preview.png';
import noteImg from '../../assets/Screenshot_2024-08-19_145240-removebg-preview.png';
import languageImg from '../../assets/Screenshot_2024-08-19_145430-removebg-preview.png';
import Modal from "../Modal/Modal";

const Profile = () => {
  const [privacyModal , setPrivacyModal] = useState(false);
  const [termsModal  , setTermsModal] = useState(false);
  const navigate = useNavigate();
  const [socials, setSocials] = useState([]);
  const [sitedata, setsitedata] = useState({});
  const [about, setshowabout] = useState(false);
  const [showterms, setshowterms] = useState(false);
  const [showreturns, setshowreturns] = useState(false);
  const [showprivacy, setshowprivacy] = useState(false);
  // const [userData, setUserData] = useState(
  //   localStorage.getItem("manjamUser")
  //     ? JSON.parse(localStorage.getItem("manjamUser"))
  //     : null
  // );

  const getScoails = () => {
    axios
      .post("https://api.manjam.shop/v4/site/social_media/getAll?type=user", {
        timeout: 8989898989,
      })
      .then((res) => {
        // console.log(res.data.message);
        setSocials(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  const getsitedata = () => {
    axios
      .get("https://api.manjam.shop/v4/site/info/getAll", {
        timeout: 8989898989,
      })
      .then((res) => {
        // console.log(res.data?.message);
        setsitedata(res?.data?.message[0]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getScoails();
    getsitedata();
  }, []);

  useEffect(() => {
    if (about || showterms || showreturns || showprivacy) {
      document.body.style.overflow = "hidden";
      console.log("hello there");
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showterms, about, showreturns, showprivacy]);

  const [userData, setUserData] = useState(
    localStorage.getItem("manjamUser")
      ? JSON.parse(localStorage.getItem("manjamUser"))
      : null
  );

  const [userBalance, setUserBalance] = useState(false);

  useEffect(() => {
    // alert("Balance");
    const FormData = require("form-data");
    let data = new FormData();
    data.append("userId", userData?.userId);
    data.append("userHash", userData?.userHash);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://taxes.like4app.com/s2s/users/userInfo",
      headers: {
        apiKey:
          "llfPTSlGKSFkS3e61XBH3SZaPOaQoDIhsq1xH9CAEEHG3MOU2D8B57umjk6xgfIA",
        Cookie: "like4card=c9bd286f87c57cd3d76dc8739f6595cd73a55673",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setUserBalance({
          b: response?.data?.data?.balance,
          c: response?.data?.data?.currency,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userData]);
  const language = useSelector((state) => state.language.lang);
   
  function handleNavigate(pathName) {
    navigate(pathName);
  }
  

  useEffect(() => {
    console.log(sitedata)
  },[sitedata])

  return (
    <>
       {privacyModal && <Modal style={{margin:"auto"}} size="350px" headerIcon="X" show={privacyModal} onClose={()=>setPrivacyModal(false)} title={language == "ar" ? "سياسة الخصوصية" : "Privacy Policy"}>
            <div style={{display:"flex" , flexDirection:"column"}}>
            <h4>{language == "ar" ? "سياسة" : "Policy"}</h4>
           <p>{language == "ar" ? sitedata?.policy_ar : sitedata?.policy}</p>
        
           <div style={{backgroundColor:"#ddd", width:"100%" ,margin:"10px 0px",height:"1px"}}></div>
           <h4>{language == "ar" ? "سياسة الإرجاع" : "Return Policy"}</h4>
           <p>{language == "ar" ? sitedata?.return_policy_ar : sitedata?.return_policy}</p>
            </div>
        </Modal>}


        {termsModal && <Modal style={{margin:"auto"}} size="350px" headerIcon="X" show={termsModal} onClose={()=>setTermsModal(false)} title={language == "ar" ? "الشروط والأحكام" : "Terms"}>
           <p>{language == "ar" ? sitedata?.terms_ar : sitedata?.terms}</p>
        </Modal>}
       
       <div className="profile_container">
      <div className="profile_header">
        {language == "ar" ? "مساء الخير يا" : "Good Evening"}{" "}
        <span className="user_name"> {userData?.username} </span> {ByeHand}
      </div>

      {/* <div className="verified">
        <div className="icon">
          <FaCircleCheck />
        </div>
        <div className="text">
          {language == 'ar' ? "حساب موثق" : "Verified Account"}
        </div>
      </div> */}

      <div style={{display:"flex",alignItems:"center" , justifyContent : "space-between"}}>
         <h3>{language == "ar"? "النقاط" : "Points"} : </h3>
         <h6>{userData && userData?.coins ? userData?.coins : 0}</h6>
      </div>
      
      <div className="recharge">
          <div className="icon">
              <FaPlus />
          </div>
          <span>
              {language == 'ar' ? "إعادة شحن الرصيد" : "Recharge"}
          </span>
      </div>

      <div style={{marginTop:"10px"}}>
      <PagesButtons language={language} navigate={navigate} />
      </div>

      <div className="profile-data-boxes">
         <div onClick={() => handleNavigate("/shopcategories")}  className="profile-box">
          <div>
          <FaShop />
          </div>
          <p>{language == "ar" ? "الفئات" : "Categories"}</p>
         </div>

         <div className="profile-box" onClick={()=> handleNavigate("/Location")}>
           <div>
             <FaLocationDot />
           </div>
           <p>{language == "ar" ? 'الموقع' : "Saved Location"}</p>
         </div>

         <div className="profile-box">
          <div>
          <FaHeadset />
          </div>
          <p>{language == "ar" ? "الدعم":"Support"}</p>
         </div>

         <div className="profile-box" onClick={() => setPrivacyModal(true)}>
          <div>
          <BsFillShieldLockFill />
          </div>
          <p>{language == "ar" ?  "سياسة الخصوصية" : "Privacy Policy"}</p>
         </div>

         <div className="profile-box" onClick={() => setTermsModal(true)}>
          <div>
          <TbCertificate />
          </div>
          <p>{language == "ar" ? "الشروط والأحكام" : "Terms"}</p>
         </div>

         <div className="profile-box">
          <div>
          <MdLanguage />
          </div>
          <p>{language == "ar" ? "اللغات": "Languages"}</p>
         </div>
      </div>
      
      {/* <p className="profile-link" onClick={() => {localStorage.removeItem("manjamUser");
                  window.location.reload();
                  return;}}>
         <span><PiSignOut /></span>
         <span>{language == "ar" ? "تسجيل الخروج" : 'Sign Out'}</span>
      </p> */}
      {/* <div className="sections">
        {[
          {
            id: 0,
            pathname: '/notification',
            icon: <MdNotificationsNone />,
            en_text: 'Notifications',
            ar_text: 'الإشعارات',
          },
          {
            id: 1,
            pathname: '/orderlogs2',
            icon: <FaListUl />,
            en_text: 'Recent Orders',
            ar_text: 'الطلبات',
          },
          {
            id: 2,
            pathname: '/shopcategories',
            icon: <LiaStoreAltSolid />,
            en_text: 'Categories',
            ar_text: 'الفئات',
          },
          {
            id: 3,
            pathname: '',
            icon: <ImLocation />,
            en_text: 'Saved Location',
            ar_text: 'الموقع',
          },
          // {
          //   id:4,
          //   pathname:'',
          //   icon: <FaUser />,
          //   text:'Profile',
          // },
          // {
          //   id:5,
          //   pathname:'',
          //   icon: <FaHeadset />,
          //   text:'Support',
          // },

          {
            id: 6,
            pathname: '',
            icon: <BsFillShieldLockFill />,
            en_text: 'Privacy Policy',
            ar_text: 'سياسة الخصوصية ',
          },
          {
            id: 7,
            pathname: '',
            icon: <TbCertificate />,
            en_text: 'Terms and Conditions',
            ar_text: 'الشروط والأحكام',
          },
          // {
          //   id:8,
          //   pathname:'',
          //   icon: <MdLanguage />,
          //   text:'language',
          // },
          {
            id: 9,
            pathname: '',
            icon: <PiSignOut />,
            en_text: 'Sign Out',
            ar_text: 'تسجيل خروج',
          },
        ]?.map((item) => {
          return (
            <div
              key={item.id}
              className="section "
              onClick={() => {
                if (item.pathname) {
                  navigate(item.pathname);
                  return;
                }

                if (item.en_text == 'Privacy Policy') {
                  setshowprivacy(true);
                  return;
                }

                if (item.en_text == 'Terms and Conditions') {
                  setshowterms(true);
                  return;
                }

                if (item.en_text == "Sign Out") {
                  localStorage.removeItem("manjamUser");
                  window.location.reload();
                  return;
                }
              }}
            >
              <div className="icon">{item.icon}</div>
              <div className="text">
                {language == 'ar' ? item.ar_text : item.en_text}
              </div>
            </div>
          );
        })}
      </div>

      {showprivacy ? (
        <div className="terms_big_div" onClick={() => setshowprivacy(false)}>
          <div className="terms_small_div">
            <h6 className="modal_title">Privacy Policy</h6>
            <p>{language == 'ar' ? sitedata.policy_ar : sitedata.policy}</p>
            <AiOutlineClose
              onClick={() => {
                setshowprivacy(false);
              }}
            />
          </div>
        </div>
      ) : null}

      {showterms ? (
        <div className="terms_big_div" onClick={() => setshowterms(false)}>
          <div className="terms_small_div">
            <h6 className="modal_title">Terms and Conditions</h6>
            <p>{language == 'ar' ? sitedata.terms_ar : sitedata.terms}</p>
            <AiOutlineClose
              onClick={() => {
                setshowterms(false);
              }}
            />
          </div>
        </div>
      ) : null} */}
    </div>
    </>
  );
};

export default Profile;
