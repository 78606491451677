// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.return_page {
  /* padding: 15px; */
  margin-bottom: 50px;
  /* padding: 4px !important; */
  padding-bottom: 4px;
  padding: 20px 4px 2px;
}

.return_page .returns {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Returns/returns.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".return_page {\n  /* padding: 15px; */\n  margin-bottom: 50px;\n  /* padding: 4px !important; */\n  padding-bottom: 4px;\n  padding: 20px 4px 2px;\n}\n\n.return_page .returns {\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
