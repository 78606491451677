// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_container {
  padding: 10px;
}

.search {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: white;
  color: #7F19A8 !important;
  /* background-color: #faf7fa; */
  padding: 8px;
  border-radius: 99px;
  box-shadow: rgba(127, 25, 168 , 0.2) 0px 2px 8px 0px;
}

.search_container input::placeholder {
  color: #7F19A8 !important;
}

.search_icon {
  color: #7F19A8;
  font-weight: bolder;
  margin-right: 10px;
}

.search {
  display: flex;
  /* align-items: center; */
}

input {
  /* width: 100%; */
  border: none;
  outline: none;
  background-color: transparent;
}

.home_categories_content_search {
  background-color: white;
  padding: 20px;
  /* box-shadow: rgba(127, 25, 168 , 0.2) 0px 2px 8px 0px; */
}

.home_offer {
  
}`, "",{"version":3,"sources":["webpack://./src/components/Home/HomeSearch/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,yBAAyB;EACzB,+BAA+B;EAC/B,YAAY;EACZ,mBAAmB;EACnB,oDAAoD;AACtD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,0DAA0D;AAC5D;;AAEA;;AAEA","sourcesContent":[".search_container {\n  padding: 10px;\n}\n\n.search {\n  display: flex;\n  flex-direction: column;\n  /* align-items: center; */\n  background-color: white;\n  color: #7F19A8 !important;\n  /* background-color: #faf7fa; */\n  padding: 8px;\n  border-radius: 99px;\n  box-shadow: rgba(127, 25, 168 , 0.2) 0px 2px 8px 0px;\n}\n\n.search_container input::placeholder {\n  color: #7F19A8 !important;\n}\n\n.search_icon {\n  color: #7F19A8;\n  font-weight: bolder;\n  margin-right: 10px;\n}\n\n.search {\n  display: flex;\n  /* align-items: center; */\n}\n\ninput {\n  /* width: 100%; */\n  border: none;\n  outline: none;\n  background-color: transparent;\n}\n\n.home_categories_content_search {\n  background-color: white;\n  padding: 20px;\n  /* box-shadow: rgba(127, 25, 168 , 0.2) 0px 2px 8px 0px; */\n}\n\n.home_offer {\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
