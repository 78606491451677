import React, { useEffect, useState } from "react";
import "./orderlogs.css";
import Header from "../header";
import { AiFillStar } from "react-icons/ai";
import PagesHeader from "../../PagesHeader/PagesHeader";
import { FiRotateCcw } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/ar";
import HeaderV3 from "../HeaderV3/HeaderV3";
import { IoIosArrowBack } from "react-icons/io";
import uploadImg from '../../assets/image_13407092.png';

const OrderLogs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [Reasons, setReasons] = useState([]);
  const [choose, setchoose] = useState("parlor");
  const [reason_id, setreason_id] = useState("");
  const [valueChoosen, setValueChoosen] = useState("return");
  const { orderdata } = location.state;
  // console.log(orderdata)
  const language = useSelector((state) => state.language.lang);
  // console.log(orderdata)\
  const [rateloading, setrateloading] = useState(false);
  const [needHelp, setNeedHelp] = useState(false);
  const [starval, setstarval] = useState(0);
  const [textareaval, settextareaval] = useState(0);
  const [text, settext] = useState("");
  const [image, setimage] = useState(null);
  const [uploadLoading, setuploadloading] = useState(false);
  const [imageLink, setimageLink] = useState("");
  const [showretbox, setshowretbox] = useState(false);
  const [showsub, setshowsub] = useState(false);
  const [quantity, setquantity] = useState(orderdata.quantity);
  const [userData, setUserData] = useState(
    localStorage.getItem("manjamUser")
      ? JSON.parse(localStorage.getItem("manjamUser"))
      : null
  );
  const handleaddrate = () => {
    setrateloading(true);
    const data_send = {
      text,
      user_id: userData?.userId,
      product_id: orderdata?.products[0]?.id,
      rate: starval,
      userName: userData?.username,
      userImage: userData?.userPicture,
    };
    // console.log(data_send);
    axios
      .post("https://api.manjam.shop/v4/product/rate", data_send)
      .then((res) => {
        // console.log(res);
        if (res.data.status == 1) {
          setstarval(0);
          settext("");
          toast.success(
            language == "ar" ? "تمت العملية بنجاح" : res.data.message
          );
        } else if (res.data.status == 0) {
          toast.error(
            language == "ar" ? "لم تتم إضافة التقييم" : res.data.message
          );
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setrateloading(false);
      });
  };

  const getReasons = () => {
    axios
      .get("https://api.manjam.shop/v4/reason/getAll")
      .then((res) => {
        setReasons(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getReasons();
  }, []);

  const handleuploadimage = () => {
    if (image == null) {
      toast.warn(language == "ar" ? "أختر صوره" : "Choose Image");
      return;
    }
    setuploadloading(true);
    const formData = new FormData();
    formData.append("image", image);
    axios
      .post(
        "https://image-uploader-ochre.vercel.app/image/upload",
        formData
      )
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(
            language == "ar" ? "تم الرفع بنجاح" : "Uploaded Succesfully"
          );
          setimageLink( res.data.imgUrl);
          // setimages([...images, res.data.message]);
          setimage(null);
        }
      })
      .finally(() => {
        setuploadloading(false);
      })
      .catch((err) => console.log(err));
  };

  const handlereturnitem = async () => {
    // console.log(data);
    let uploadedImage = "";
    const formData = new FormData();
    formData.append("image", image);
    await axios
      .post(
        "https://image-uploader-ochre.vercel.app/image/upload",
        formData
      )
      .then((res) => {
        if (res.data.status === "success") {
          uploadedImage =  res.data.imgUrl;
          // setimages([...images, res.data.message]);
          setimage(null);
        }
      });

    const data_send = {
      order_id: orderdata?.id,
      quantity: quantity,
      price: orderdata?.newPrice * quantity,
      imageLink: uploadedImage,
      reason: choose,
      prop_id: orderdata?.props_ids,
      prop_value_id: orderdata?.props_value_ids,
      user_id: userData?.userId,
      return_reason_id: reason_id,
    };
    // console.log(data_send);
    axios
      .post("https://api.manjam.shop/v4/return/orderReturn", data_send)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success(
            language == "ar" ? "تمت العملية بنجاح" : res.data.message
          );
          // navigate("/orderlogs",{replace:true})
        } else if (res.data.status == 0) {
          toast.error(
            language == "ar"
              ? res.data.message.toLowerCase() == "order has returned"
                ? "فشلت العملية لقد تم استرجاع المنتج بالفعل "
                : res.data.message
              : res.data.message
          );
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAddRedport = async () => {
    const data_send = {
      order_id: orderdata?.id,
      user_id: userData?.userId,
      user_email: userData?.userEmail,
      user_name: userData?.username,
      message: needHelp?.message,
    };
    // console.log(data_send);
    axios
      .post("https://api.manjam.shop/v4/order/addReports", data_send)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success(
            language == "ar" ? "تمت العملية بنجاح" : res.data.message
          );
          // navigate("/orderlogs",{replace:true})
        } else if (res.data.status == 0) {
          toast.error(
            language == "ar"
              ? res.data.message.toLowerCase() == "Wrong Something Happened"
                ? "حدث خطأ ما"
                : res.data.message
              : res.data.message
          );
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log(orderdata)
  } , [orderdata])

  return (
    <div style={{ marginBottom: "80px" }}>
      <div className="orderslogspage">

      <div className='head_v3'>
      <div className='back_arrow'>
        <IoIosArrowBack
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
        <p className="order_id">{language == "ar" ?"اوردر" : "Order"}# {orderdata?.id}</p>
      <div className='notification-bar'>
      <h4
          style={{ textAlign: "end", fontSize: "15px" }}
          onClick={() => setNeedHelp({ id: orderdata?.id })}
        >
          {language == "ar" ? "تحتاج مساعدة ؟ " : "Need Help ?"}
        </h4>
      </div>

    </div>
  
        <div className="orderslog_page_content">
        <div className="product_log">
          <div className="left">
            <div style={{ height: "100%" }} className="img">
              <img
                style={{ borderRadius: "10px" }}
                src={orderdata?.products[0]?.colors[0]?.images[0]?.link}
                alt=""
              />
            </div>
            <div className="product_details">
              <div className="tabel-row">
                 <div className="table_field">
                 <div>
                  {language == "ar" ? "اسم المنتج" : "Name"} :{" "}
                </div>
                <div>
                  {language == "ar"
                    ? orderdata?.product_label
                    : orderdata?.product_label}
                </div>
                 </div>
                  
              </div>
              <div className="tabel-row">
                <div className="table_field">
                <div>{language == "ar" ? "الفئة" : "Category"}: </div>
                <div>
                  {language == "ar"
                    ? orderdata?.products[0]?.category_name_ar
                    : orderdata?.products[0]?.category_name}
                </div>
                </div>
              </div>

              <div className="table_field">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#7f19a8",
                  textTransform: "capitalize",
                  fontWeight: "800",
                  fontSize:"12px",
                }}
              >
                <p style={{fontSize:"12px",}}>{language == "ar" ? "حالة المنتج:" : "Grade:"}</p>
                <p style={{ margin: "0px" }}>
                  {orderdata?.products[0]?.grade}{" "}
                </p>
              </div>
              </div>

              <div className="table_field">
              <div>
                <p
                  style={{
                    fontSize:"12px",
                    color: "#7f19a8",
                    textTransform: "capitalize",
                    fontWeight: "800",
                  }}
                >
                  {language == "ar" ? "الحاله" : "Status"}:{" "}
                </p>
                <h6
                  className={
                    orderdata?.status == "completed"
                      ? "orderstatus completed"
                      : "orderstatus pending"
                  }
                  style={{
                    background: "#7f19a8",
                    color: "white",
                    padding: "10px",
                    borderRadius: "17px",
                    textTransform: "capitalize",
                  }}
                >
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    {language == "ar"
                      ? orderdata.status == "pending"
                        ? "في انتظار رد الموظف"
                        : orderdata.status == "in_progress"
                        ? "في انتظار رد بوابةالدفع"
                        : orderdata.status == "on_way"
                        ? "في الطريق"
                        : orderdata.status == "completed" ||
                          orderdata.status == "confirmed"
                        ? "تمت الموافقة على الطلب"
                        : orderdata.status == "canceled" ||
                          orderdata.status == "reject"
                        ? "تم رفض الطلب"
                        : orderdata.status == "under_shipping"
                        ? "الطلب تحول لشركة الشحن"
                        : orderdata.status == "out_for_delivery"
                        ? "الطلب في الطريق"
                        : orderdata.status == "delivered"
                        ? "تم توصيل الطلب"
                        : orderdata.status == "Payment Failed"
                        ? "لم تنجح عملية الدفع"
                        : "هناك مشكلة في الطلب"
                      : orderdata.status}
                  </span>
                </h6>
              </div>
              </div>
            </div>
          </div>
        </div>

        {/* <p className='orderpara'>Saturday, 23 March 2022  09:22 KSA</p> */}
        <div style={{display:"flex",alignItems:"center",gap:"5px",marginTop:"10px",fontSize:"18px" , width:"fit-content"}}>
          <p style={{color:"#fe8d2a" , margin:"auto" , fontWeight:"500"}}>{language == "ar" ?"بدايه" : "Start"}:</p>
          <p className="orderDate" style={{margin:"auto"}}>
            {language == "ar"
              ? moment(orderdata?.createdAt).locale("ar-kw").format('M/D/YYYY h:mmA')
              : moment(orderdata?.createdAt).locale("en").format('M/D/YYYY h:mmA')}
          </p>
        </div>
        <div className="product_log_others retu">
          <div className="other_details_logs">
            <div>
              <p>{language == "ar" ? "رقم الطلب" : "Order Number"}</p>
              <h6>{orderdata?.id}</h6>
            </div>
          </div>

          <div className="other_details_logs">
            
            <div>
              <p>{language == "ar" ? "الكمية" : "Quantity"}</p>
              <h6>
                {orderdata?.quantity} {language == "ar" ? "قطع" : "pieces"}
              </h6>
            </div>
          </div>

          <div className="other_details_logs">
            <div>
              <p>{language == "ar" ? "الكلى" : "total"}</p>
              <h6>
               $
                {orderdata?.grand_price_without_tax * 1}{" "}
              </h6>
            </div>
            <div>
              <p>{language == "ar" ? "التقيم" : "Rating"}</p>
              <h6>
                <AiFillStar
                  style={{
                    color: "#7f19a8",
                  }}
                />
                <AiFillStar
                  style={{
                    color: "#7f19a8",
                  }}
                />
                <AiFillStar
                  style={{
                    color: "#7f19a8",
                  }}
                />
                <AiFillStar
                  style={{
                    color: "#7f19a8",
                  }}
                />
                <AiFillStar
                  style={{
                    color: "#7f19a8",
                  }}
                />
              </h6>
            </div>

        

          </div>

          <div className="other_details_logs">
            <div>
              <p>{language == "ar" ? "الخصم" : "Discount"}</p>
              <h6>
                ${" "}
                {parseFloat(orderdata?.old_price) -
                  parseFloat(orderdata?.new_price)}
              </h6>
            </div>
            <div>
              <p>{language == "ar" ? "وسيلة الشحن" : "Shipping Method"}</p>
              <h6>
                {language == "ar"
                  ? orderdata?.shipping?.title_ar
                  : orderdata?.shipping?.title}
              </h6>
            </div>
          </div>
          <div className="other_details_logs">

            {/* {console.log(orderdata)} */}
            <div>
              <p>{language == "ar" ? "العنوان" : "Address"}</p>
              <h6>{orderdata?.address}</h6>
            </div>
          </div>
         <div className="payment-method_contanier">
              <p style={{color:"#7f19a8" , fontSize:"17px",fontWeight:"600"}}>{language == "ar" ? "وسيلة الدفع" : "Payment Method"}</p>
              <div className="order_payment-method">
                <div className="payment_method_content">
                  <p className="payment_method_apply">Checkout</p>
                  <div className="payment_method_text">
                     <p>{language == "ar"?"حالة الدفع" : "PAYMENT status"}</p>
                     <p>{orderdata?.status}</p>
                   </div> 
                </div>

                <div style={{display:"flex",padding:"20px",alignItems:"center",color:"white"}}>
                  <p>{language == "ar" ? "الإجمالي شامل الضريبة":"Total including Tax"}</p>
                  <p style={{fontWeight:"600",fontSize:"17px"}}>{orderdata?.grand_price * 1} {localStorage.getItem("store") == "ksa"
                            ? language == "ar"
                              ? "ر.س"
                              : "SAR"
                            : language == "ar"
                            ? "د.إ"
                            : "AED"}</p>
                </div>

              </div>
            </div>
        </div>
        
      </div>

      {orderdata.status == "delivered" && 
      <>
        <div className="overall_rating">
         <div className="rate_title">
         <h5>
          {language == "ar"
            ? "تقييمك العام لهذا المنتج"
            : "Rate This order Now"}
        </h5>
        <p>Rate 1 of 5 Starts</p>
         </div> 

        <div className="stars">
          {[1, 2, 3, 4, 5].map((item) => {
            return (
              <AiFillStar
                style={{
                  color: item <= starval ? "#FE8D2A" : "#B6B4B0",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setstarval(item);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="share">
        <h4>{language == "ar" ? "مشاركة تقيمك" : "Order Note"}</h4>
        <textarea
          value={text}
          maxLength={500}
          onChange={(e) => {
            settextareaval(e.target.value.length);
            settext(e.target.value);
          }}
          placeholder={
            language == "ar" ? "ستظهر ملاحظتك في مراجعات العملاء" : "Your Note Will Show In Customer Reviews"
          }
        ></textarea>
        <p>{textareaval}/500</p>
        {/* <button
          onClick={() => {
            handleaddrate();
          }}
        >
          {language == 'ar' ? "تقيم" : "Add Rate"}
        </button> */}
      </div>
      </>}
      <button
        onClick={() => {
          handleaddrate();
        }}
        className="returnbtn"
      >
        {/* <FiRotateCcw /> */}
       {orderdata?.status == "delivered" &&  <span>{language == "ar" ? "إضافة تقييم" : "Submit"}</span>}
       {orderdata?.status !== "delivered" &&  <span onClick={() => navigate(-1)}>{language == "ar" ? "عودة" : "Back"}</span>}
      </button>
      {showsub ? (
        <div className="sub_div">
          <div className="sub">
            <div className="bar"></div>
            <button
              onClick={() => {
                setshowretbox(true);
                setshowsub(false);
                // navigate("/returnitem",{state:{data:orderdata}})
              }}
            >
              {language == "ar" ? "ارجاع الطلب" : "Return This Order"}
            </button>
            <button>{language == "ar" ? "فاتوره" : "Invoice"}</button>
            <button
              onClick={() => {
                setshowsub(false);
              }}
            >
              {language == "ar" ? "إلغاء" : "Cancel"}
            </button>
          </div>
        </div>
      ) : null}
      {showretbox ? (
        <div className="retunrbox">
          <div className="return_div">
            <div></div>
            <h4>
              {language == "ar"
                ? orderdata?.products[0].title_ar
                : orderdata?.products[0].title}
            </h4>
            <p>
              {language == "ar"
                ? "الرجاء تحديد عدد العناصر التي تريد إرجاعها"
                : "Please select number of items you want to return"}
            </p>
            <div>
              <button
                onClick={() => {
                  if (quantity > 1) {
                    setquantity(quantity - 1);
                  }
                }}
              >
                -
              </button>
              <p>{quantity}</p>
              <button
                onClick={() => {
                  if (quantity < orderdata.quantity) {
                    setquantity(quantity + 1);
                  }
                }}
              >
                +
              </button>
            </div>
            <div className="actions">
              <button
                onClick={() => {
                  setshowretbox(false);
                }}
              >
                {language == "ar" ? "إلغاء" : "cancel"}
              </button>
              <button
                onClick={() => {
                  navigate("/returnitem", {
                    state: { data: orderdata, quantity },
                  });
                }}
              >
                {language == "ar" ? "إتمام" : "submit"}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {needHelp ? (
        <>
          <div className="card_overlay"></div>
          <button
            class="close-button"
            onClick={() => {
              setNeedHelp(false);
            }}
          >
            X
          </button>

          <div class="payment-card">
            <div class="title" style={{ textAlign: "center" , fontWeight:"800 !important" }}>
              {language == "ar" ? "تحتاج مساعدة ؟ " : "Need Help ?"}
            </div>
            <div className="radioFlex">
              <div class="radio-group radioFlex">
                <label class="radio-option" style={{ fontSize: "14px" }}>
                  <input
                    type="radio"
                    name="return-reason"
                    value="return"
                    onChange={() => {
                      setValueChoosen("return");
                    }}
                    checked={valueChoosen == "return"}
                  />{" "}
                  {language == "ar" ? "استرجاع" : "Return"}
                </label>
                <label class="radio-option" style={{ fontSize: "14px" }}>
                  <input
                    type="radio"
                    name="return-reason"
                    value="help"
                    onChange={() => {
                      setValueChoosen("help");
                    }}
                    checked={valueChoosen == "help"}
                  />{" "}
                  {language == "ar" ? " مساعدة ؟ " : "Need Help ?"}
                </label>
              </div>
            </div>
            {valueChoosen == "return" ? (
              <form
                action="/submit-form"
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();
                  handlereturnitem();
                }}
              >
                <div className="item_radios">
                  <p style={{ color: "#7f19a8", fontWeight:"900" }}>
                    {language == "ar"
                      ? "لماذا تريد إرجاع هذا العنصر؟"
                      : "Why do you want to return this item ?"}
                  </p>
                  {/* {console.log(Reasons , Reasons.length)} */}
                  {Reasons != "no reasons" && Reasons && Reasons.length
                    ? Reasons?.map((item, index) => {
                        return (
                          <div>
                            <label
                              onClick={() => {
                                setchoose(item.text);
                                setreason_id(item.id);
                              }}
                              htmlFor={index}
                            >
                              {language == "ar" ? item.text_ar : item.text}
                            </label>
                            <input
                              onClick={() => {
                                setchoose(item.text);
                                setreason_id(item.id);
                              }}
                              checked={choose == item.text}
                              type="radio"
                              name={index}
                              id={index}
                            />
                          </div>
                        );
                      })
                    : null}
                </div>
                <div class="upload-section">
                  <div style={{ color: "#7f19a8", fontWeight:"900" }} class="upload-instruction">
                    Upload images <b>(Optional)</b>
                  </div>
                  <label for="file-upload" class="upload-button">
                    <span>Please Upload Photoes For Your return's packaging to show us the condition</span>
                    <img
                      src={uploadImg}
                      alt=""
                      style={{ width: "40px", height: "40px", objectFit:"contain" }}
                    />
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    name="return-images"
                    onChange={(e) => setimage(e.target.files[0])}
                  />
                </div>
                <button type="submit" class="send-button" style={{backgroundColor:"#7F19A8" , borderRadius:"100px", color:"white"}}>
                  Send
                </button>
              </form>
            ) : (
              <form
                onClick={(e) => {
                  e.preventDefault();
                  handleAddRedport();
                }}
                method="post"
              >
                <div className="item_radios">
                  <label htmlFor="">
                    {language == "ar" ? "المشكلة" : "Your Problem"}
                  </label>
                  <textarea
                    onChange={(e) => {
                      setNeedHelp({ ...needHelp, message: e.target.value });
                    }}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    style={{
                      width: "100%",
                      outline: "none",
                      border: "01px solid grey",
                      borderRadius: "16px",
                    }}
                  ></textarea>
                </div>

                <button type="submit" class="send-button">
                  Send
                </button>
              </form>
            )}
          </div>
        </>
      ) : null}
        </div>
    </div>
  );
};

export default OrderLogs;
