import React from 'react'

const CircleProgress = ({ progress , width = 200, height = 200, color = '#6bdba7' , children }) => {
  const strokeDasharray = 2 * 3.14 * 90
  const strokeDashoffset = strokeDasharray * ((100 - progress) / 100)

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="-25 -25 250 250"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: 'rotate(-90deg)'
        }}
      >
        <circle
          r={90}
          cx={100}
          cy={100}
          fill="transparent"
          stroke="#e0e0e0"
          strokeWidth="16px"
          strokeDasharray={`${strokeDasharray}px`}
          strokeDashoffset={0}
        />
        <circle
          r={90}
          cx={100}
          cy={100}
          stroke={color}
          strokeWidth="16px"
          strokeLinecap="round"
          strokeDashoffset={`${strokeDashoffset}px`}
          fill="transparent"
          strokeDasharray={`${strokeDasharray}px`}
        />
        <text
          x="71px"
          y="114px"
          fill={color}
          fontSize="44px"
          fontWeight="bold"
          style={{
            display: 'none',
            transform: 'rotate(90deg) translate(0px, -196px)'
          }}
        >
          {progress}
          {'%'}
        </text>
      </svg>

      <div
        className=""
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50% , -50%)',
          color: color,
          fontWeight: 'bolder',
          fontSize: '23px'
        }}
      >
        <p>Joiners</p>
        <div>
            <p>5 0f 100</p>
        </div>
      </div>
    </div>
  )
}

export default CircleProgress