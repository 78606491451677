


import React from 'react'
import { RxCountdownTimer } from "react-icons/rx";
import { LuBadgePercent } from "react-icons/lu";
import { AiOutlineFire } from "react-icons/ai";
import { RiFireLine } from "react-icons/ri";
import { MdOutlineNotifications } from "react-icons/md";






import "./style.css"

const PagesButtons = ({language, navigate}) => {
  return (
    <div className='pages_buttons_coantainer'>
        <div className="buttons">
          <div className='button_text' onClick={()=>navigate("/orderlogs2")}>
            <div className="button">
            <RxCountdownTimer />
            </div>
            <div className='text'>{language == "ar" ? "طلباتي" :"My Orders"}</div>

          </div>
          <div className='button_text' onClick={()=>navigate("/AllOffers")}>
            <div className="button">
            <LuBadgePercent />
            </div>
            <div className='text'>{language == "ar" ? "العروض" :"Offers"}</div>

          </div>
          <div className='button_text' onClick={()=>navigate("/otherof")}>
            <div className="button">
            <RiFireLine />
            </div>
            <div className='text'>{language =="ar" ? "مناقصات" :"Bidding"}</div>

          </div>
          <div className='button_text' onClick={()=>navigate("/notification")}>
            <div className="button">
            <MdOutlineNotifications />
            </div>
            <div className='text'>{language=="ar"?"الإشعارات":"Notifications"}</div>

          </div>
          {/* <div className="button">
          <LuBadgePercent />
          </div>
          <div className="button">
          <RiFireLine />
          </div>
          <div className="button">
          <MdOutlineNotifications />
          </div> */}
        </div>
    </div>
  )
}

export default PagesButtons
