import React from 'react';
import AboutSlider from './AboutSlider';
import { useNavigate } from 'react-router';
import logo from '../../assets/New/logo.jpg';

export default function About() {
  const navigate = useNavigate();

  return (
    <>
      <div className="AboutPage position-relative vh-100">
        <div
          onClick={() => navigate(`/home`)}
          type="button"
          className="SkipBtn h4 text-white"
        >
          Skip
        </div>
        <div className="AboutLogo">
          <img src={logo} alt="" />
        </div>
        <div className="position-absolute w-100 h-100 my-5 sliderContain">
          <AboutSlider />
        </div>
      </div>
    </>
  );
}
