import React, { useEffect, useState } from "react";
import PagesHeader from "../../PagesHeader/PagesHeader";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import Countdown from "react-countdown";
import "./categoryproducts.css";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import { default as Pusher, default as pusherJs } from "pusher-js";
import HeaderV3 from "../HeaderV3/HeaderV3";

const AllOffers = () => {
  const location = useLocation();
  const language = useSelector((state) => state.language.lang);
  const navigate = useNavigate();
  const categoryproduct = location.state?.categoryproduct;
  const name = location.state?.name;
  const name_ar = location.state?.name_ar; // console.log(name)
  // console.log(name_ar)
  // console.log(categoryproduct)
  const [products, setproducts] = useState([]);
  const [offers, setoffers] = useState([]);
  const [pageloading, setpageloading] = useState(true);
  const [notifyLoading, setNotifyLoading] = useState(false);
  const [list, setList] = useState(false);
  const [userData, setUserData] = useState(
    localStorage.getItem("manjamUser")
      ? JSON.parse(localStorage.getItem("manjamUser"))
      : null
  );

  const notifyMe = (id) => {
    setNotifyLoading(true);
    const data_send = {
      user_id: userData?.userId,
      offer_id: id,
    };

    axios
      .post("https://api.manjam.shop/v4/offers/notifyMe", data_send)
      .then((res) => {
        toast.success(
          language == "ar"
            ? "شكرا, سوف نخبرك.!"
            : "Thanks, We will notify you.!"
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setNotifyLoading(false);
      });
  };

  const getcategoryProducts = () => {
    axios
      .get(
        `https://api.manjam.shop/v4/product/getProductsByCategory?id=${categoryproduct}`
      )
      .then((res) => {
        if (Array.isArray(res.data.message)) {
          setproducts(res.data.message);
        }
      });
  };

  const getOffers = () => {
    const data_send = {
      type: "user",
    };
    axios
      .post("https://api.manjam.shop/v4/offers/select_offers", data_send)
      .then((res) => {
        // console.log(res.data.message);
        if (Array.isArray(res.data.message)) {
          // console.log(res.data.message)
          // setoffers(res.data.message);
          const storeName = parseInt(userData?.storeId) == 2 ? "ksa" : "uae";
          let alloffers = [
            ...res.data.message.filter(
              (item) =>
                item?.store?.filter(
                  (item) => item == storeName
                )?.length
            ),
          ];
          let pushedoffers = alloffers.filter((item) => item?.isTendered == 0);
          setoffers(pushedoffers);
          // console.log(pushedoffers)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setpageloading(false);
      });
  };

  const renderer = ({
    total,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
    completed,
  }) => {
    if (completed) {
      // getProducts();
      return null;
    } else {
      // Render a countdown
      <span>
        {hours}:{minutes}:{seconds}
      </span>;
      return (
        <>
          {days > 0 ? (
            <>
              <div>
                <h6 style={{ color: "#7f19a8" }}>
                  {days < 10 ? "0" + days : days}
                </h6>
                <h5>days</h5>
              </div>
              <span>:</span>
            </>
          ) : null}
          <div>
            <h6 style={{ color: "#7f19a8" }}>
              {hours < 10 ? "0" + hours : hours}
            </h6>
            <h5>Hours</h5>
          </div>
          <span>:</span>
          <div>
            <h6 style={{ color: "#7f19a8" }}>
              {minutes < 10 ? "0" + minutes : minutes}
            </h6>
            <h5>Mins</h5>
          </div>
          {days <= 0 ? (
            <>
              <span>:</span>
              <div>
                <h6>{seconds < 10 ? "0" + seconds : seconds}</h6>
                <h5>seconds</h5>
              </div>
            </>
          ) : null}
        </>
      );
    }
  };

  useEffect(() => {
    getcategoryProducts();
    getOffers();
  }, []);

  return (
    <div className="categoryproducts">
      <HeaderV3
        onPress={() => {
          navigate(-1);
        }}
        title={language == "ar" ? "كل العروض" : "All Offers"}
      />
      <div className="category_products_content">
        <div
          onClick={() => setList(!list)}
          className={`d-flex ${
            language == "ar" ? "justify-content-start" : "justify-content-end"
          }`}
        >
          {list ? (
            <i class="fa-solid fs-1 mx-2 mb-2 text-primary fa-list"></i>
          ) : (
            <i class="fa-solid fs-2 mx-2 mb-2 text-primary fa-grip"></i>
          )}
        </div>
        <div className="offersList">
          {pageloading ? (
            <div>
              <Skeleton count={10} />
            </div>
          ) : offers && offers.length > 0 ? (
            offers.map((item, index) => {
              return (
                <div
                  className={
                    item.time_av_after.days >= 0 &&
                    item.time_av_after.hours >= 0 &&
                    item.time_av_after.minutes >= 0 &&
                    item.time_av_after.seconds >= 0
                      ? !list
                        ? "withOffer rounded-4 p-1"
                        : "withOffer rounded-4 p-1 list"
                      : !list
                      ? "withOffer rounded-4 withOutOffer"
                      : "withOffer rounded-4 withOutOffer list"
                  }
                  // onClick={() => {
                  //   // navigate("/productdetaisldes",{state:{productdata:item,id:item.products[0].id}})
                  // }}
                >
                  {/* <div className="notify" onClick={() => notifyMe(item?.id)}>
                  {!notifyLoading ? (
                    <>
                      {" "}
                      <img src={require("../../assets/notpil.png")} alt="" />
                      <h6>{language == 'ar' ? "إضافة تنبيه" : "Notify Me"}</h6>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div> */}
                  <div className="offerImg w-25 mx-1">
                    <img
                      src={item?.products[0]?.colors[0]?.images[0]?.link}
                      className=""
                      alt=""
                    />
                    {item.time_av_after.days >= 0 &&
                    item.time_av_after.hours >= 0 &&
                    item.time_av_after.minutes >= 0 &&
                    item.time_av_after.seconds >= 0 ? (
                      <div className="spinnerContain">
                        <div className="spinner">
                          <div class="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <div className="soonDiv soonDivSecond">
                            {language == "ar" ? "قريبا" : "Soon"}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="mx-1">
                    <h6 className=" p-0">
                      {language == "ar"
                        ? item?.products[0]?.title_ar
                        : item?.products[0]?.title}
                    </h6>
                    <p className="p-0">
                      {language == "ar"
                        ? item?.products[0]?.description_ar
                            .split(" ")
                            .slice(0, 3)
                            .join(" ")
                        : item?.products[0]?.description
                            .split(" ")
                            .slice(0, 3)
                            .join(" ")}{" "}
                      . . .
                    </p>
                    <hr />
                    <div className="d-flex align-items-center gap-2 mb-1">
                      {/* <h5>{language == 'ar' ? "سعر المنتج" : "item price"}</h5> */}
                      <h6 className="text-muted small">
                        {" "}
                        $
                        <del>
                          {
                            item?.products[0].colors[0].props[0].values[0]
                              .old_price
                          }
                        </del>
                      </h6>
                      <h6 className="priceClr">
                        {
                          item?.products[0].colors[0].props[0].values[0]
                            .new_price
                        }
                        $
                        {/* ${(Math.floor(item?.price*1 - ((item?.price*1) * (item?.discount / 100))))} */}
                      </h6>
                    </div>
                    <div className="d-flex gap-4 justify-content-between">
                      {item.time_av_after.days <= 0 &&
                      item.time_av_after.hours <= 0 &&
                      item.time_av_after.minutes <= 0 &&
                      item.time_av_after.seconds <= 0 ? (
                        <button
                          className="btn btn-sm btn-primary h-25 w-auto rounded-2 mt-auto"
                          onClick={() => {
                            navigate("/productdetails?offer_id=" + item?.id, {
                              state: {
                                id: item?.products[0]?.id,
                                offer: item,
                              },
                            });
                          }}
                        >
                          {language == "ar" ? "اشتري الآن" : "Buy Now"}
                        </button>
                      ) : null}
                      <div className="offer_time">
                        <Countdown
                          date={Date.now() + item?.time_av_after?.milliSeconds}
                          renderer={renderer}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh",
              }}
            >
              <img
                style={{ maxWidth: "100%" }}
                src={require("../../assets/nodata.png")}
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllOffers;
