import React, { useEffect, useState } from "react";
import "./successPurchase.css";
import HomeHeader from "../Home/HomeHeader/HomeHeader";
import base64 from "base-64";
import axios from "axios";
import { Loader } from "rsuite";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
function Successfullcheckout() {
  const [data, getData] = useState(false);
  const [useLoading, setUseLoading] = useState(false);
  const [order_data, setOrderData] = useState(null);
  const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const success = urlParams.get("success");
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const success = urlParams.get("success");
    const type = urlParams.get("type");
    if (type != "addresses_callback")
      try {
        setUseLoading(true);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const dataType = urlParams.get("data");
        const type = urlParams.get("type");
        const token = JSON.parse(base64.decode(dataType));
        setOrderData(token);
        let data = new FormData();
        data.append(
          "userId",
          JSON.parse(localStorage.getItem("manjamUser"))?.userId
        );
        data.append(
          "userHash",
          JSON.parse(localStorage.getItem("manjamUser"))?.userHash
        );
        data.append("appOrderRef", token?.order_id);
        data.append("appId", "16");
        data.append(
          "apikey",
          "llfPTSlGKSFkS3e61XBH3SZaPOaQoDIhsq1xH9CAEEHG3MOU2D8B57umjk6xgfIA"
        );

        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "https://api.manjam.shop/v4/product/getAll?type=admin",
          headers: {
            apiKey:
              "llfPTSlGKSFkS3e61XBH3SZaPOaQoDIhsq1xH9CAEEHG3MOU2D8B57umjk6xgfIA",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            const checkResponse = response?.data?.data?.statusId;
            // const checkResponse = 2;
            getData(checkResponse);
            if (!parseInt(success)) {
              let data = JSON.stringify({
                id: token?.order_id,
                status: "Payment Failed",
                payementId: token?.payementId,
              });

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://api.manjam.shop/v4/order/changeStatus",
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {
                  console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                  console.log(error);
                });
              axios
                .post("https://api.manjam.shop/v4/order/orderPayUpdating", {
                  id: token?.order_id,
                  payementId: token?.payementId,
                })
                .then((response) => {
                  console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (
              parseInt(success)
            ) {
              let data = {
                id: token?.order_id,
                status: "pending",
              };

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://api.manjam.shop/v4/order/changeStatus",
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {
                  console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                  console.log(error);
                });
              axios
                .post("https://api.manjam.shop/v4/order/orderPayUpdating", {
                  id: token?.order_id,
                  payementId: token?.payementId,
                })
                .then((response) => {
                  console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              let data = {
                id: token?.order_id,
                status: "in_progress",
              };

              let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://api.manjam.shop/v4/order/changeStatus",
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios
                .request(config)
                .then((response) => {
                  console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                  console.log(error);
                });
              axios
                .post("https://api.manjam.shop/v4/order/orderPayUpdating", {
                  id: token?.order_id,
                  payementId: token?.payementId,
                })
                .then((response) => {
                  console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setUseLoading(false);
          });
      } catch (err) {
        console.log(err);
        setUseLoading(false);
      }
  }, []);
  const language = useSelector((state) => state.language.lang);
  const [address, setAddress] = useState("");
  const getAddress = (address) => {
    try {
      setAddress(JSON.parse(address));
    } catch (err) {
      console.log(err);
    }
    // console.log(address);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("data");
    const type = urlParams.get("type");
    const token = base64.decode(data);
    if (type == "addresses_callback") {
      getAddress(token);
    }
  }, []);

  useEffect(() => {
    if (address) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const data = urlParams.get("data");
      const type = urlParams.get("type");
      const token = base64.decode(data);
      axios
        .post("https://api.manjam.shop/v4/order/orderPayUpdating")
        .then((response) => {
          if (response?.data?.status) {
            toast.success(
              language == "ar" ? "تم التحديث بنجاح" : response?.data?.message
            );
            window.location.href = "/home";
          }
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .post("https://api.manjam.shop/v4/order/orderAddressUpdating", {
          id: token?.order_id,
          address: `${address?.address?.country} - ${address?.address?.city} - ${address?.address?.area} - ${address?.address?.street}`,
        })
        .then((response) => {
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [address]);
  const queryStringe = window.location.search;
  const urlParamse = new URLSearchParams(queryStringe);
  const datae = urlParamse.get("data");
  const typee = urlParamse.get("type");
  if (typee == "addresses_callback") return <h2>Loading...</h2>;
  return (
    <>
      <HomeHeader />
      {!useLoading ? (
        <div
          style={{ marginBottom: "80px" }}
          className="checkout shop_categories_content"
        >
          <div class="card_overlay"></div>
          <div className="successPurchase_i payement_method_i">
            {parseInt(success) ? (
              <img
                src="https://res.cloudinary.com/dsqlywnj5/image/upload/v1690291917/animation_lkic4h8v_small_g3g1bm.gif"
                alt=""
              />
            ) : !parseInt(success) ? (
              <img
                src="https://i0.wp.com/nrifuture.com/wp-content/uploads/2022/05/comp_3.gif?fit=800%2C600&ssl=1"
                alt=""
              />
            ) : data == 8 ? (
              <img
                src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/55995f77392207.5c869a30b1c8d.gif"
                alt=""
              />
            ) : (
              <img
                src="https://i0.wp.com/nrifuture.com/wp-content/uploads/2022/05/comp_3.gif?fit=800%2C600&ssl=1"
                alt=""
              />
            )}
            <p>
              {language == "ar" ? "عملية الدفع" : "Your Purchase has"}{" "}
              {data == 2 || data == 1 ? (
                <>{language == "ar" ? "تمت بنجاح" : "successfully Done"}</>
              ) : data == 4 || data == 3 ? (
                <>{language == "ar" ? "فشلت" : "Failed"}</>
              ) : data == 8 ? (
                <>
                  {language == "ar"
                    ? "ننتظر الرد من بوابة الدفع"
                    : "Waiting For Feadback"}
                </>
              ) : language == "ar" ? (
                "بها خطأ ولم تتم بالشكل المطلوب"
              ) : (
                " an error"
              )}{" "}
            </p>
            {data != 8 ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  const sentToken = base64.encode(
                    JSON.stringify({
                      is_select: true,
                      app_id: "16",
                      meta_data: "",
                      order_id: order_data?.order_id,
                      callback_base_url:
                        window.location.origin +
                        "/successfullcheckout?type=addresses_callback&order_id=" +
                        order_data?.order_id,
                    })
                  );
                  window.location.href =
                    "likecard://like4card.page.link/addresses?data=" +
                    sentToken;
                }}
              >
                {language == "ar" ? "اختيار العنوان" : "Ship Now"}
              </button>
            ) : null}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Successfullcheckout;
