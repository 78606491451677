// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabel_details {
  padding: 60px 0;
  margin: 10px 20px;
}

.tabel_details .tabel-row {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #373737;
  font-size: 15px;
  font-weight: bolder;
  border-bottom: 1px solid #373737;
}

.tabel_details .details {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #373737;
}

.tabel_details {
}

.details h4 {
  color: #373737 !important;
  margin-bottom: 10px;
}

.variants {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.variants .var {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #f38c25ac;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.variants .var .price {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.variants .var .price h4 {
  font-size: 15px;
  color: #373737;
}
`, "",{"version":3,"sources":["webpack://./src/components/prodDetails/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,gCAAgC;AAClC;;AAEA;AACA;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".tabel_details {\n  padding: 60px 0;\n  margin: 10px 20px;\n}\n\n.tabel_details .tabel-row {\n  padding: 15px 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  color: #373737;\n  font-size: 15px;\n  font-weight: bolder;\n  border-bottom: 1px solid #373737;\n}\n\n.tabel_details .details {\n  text-align: center;\n  margin-top: 20px;\n  padding-bottom: 20px;\n  border-bottom: 1px solid #373737;\n}\n\n.tabel_details {\n}\n\n.details h4 {\n  color: #373737 !important;\n  margin-bottom: 10px;\n}\n\n.variants {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 30px;\n  flex-wrap: wrap;\n  margin-top: 30px;\n}\n\n.variants .var {\n  width: 150px;\n  height: 150px;\n  border-radius: 50%;\n  border: 3px solid #f38c25ac;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 20px;\n}\n.variants .var .price {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.variants .var .price h4 {\n  font-size: 15px;\n  color: #373737;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
