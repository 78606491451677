export const HomeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconsax/Broken/home2">
      <path
        id="Vector"
        d="M22 10.498C22 9.28797 21.19 7.73797 20.2 7.04797L14.02 2.71797C12.62 1.73797 10.37 1.78797 9.02 2.83797L3.63 7.03797C2.73 7.73797 2 9.22797 2 10.358V17.768C2 20.088 3.89 21.988 6.21 21.988H17.79C20.11 21.988 22 20.088 22 17.778V14.678M12 17.988V14.988"
        stroke="#050505"
        stroke-opacity="0.5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const ReceiptIcons = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconsax/Broken/receipt1">
      <path
        id="Vector"
        d="M20.5 7.04C20.5 3.01 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.96 4.96 21.59 6.73 19.69L6.74 19.68C7.56 18.81 8.81 18.88 9.52 19.83L10.53 21.18C11.34 22.25 12.65 22.25 13.46 21.18L14.47 19.83C15.19 18.87 16.44 18.8 17.26 19.68C19.04 21.58 20.49 20.95 20.49 18.29V11M8 7H16M9 11H15"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export const NotificationIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconsax/Broken/notificationbing">
      <path
        id="Vector"
        d="M11.9999 6.441V9.771M20.5899 15.17C21.3199 16.39 20.7399 17.97 19.3899 18.42C14.6086 20.01 9.44121 20.01 4.65993 18.42C3.21993 17.94 2.66993 16.48 3.45993 15.17L4.72993 13.05C5.07993 12.47 5.35993 11.44 5.35993 10.77V8.67C5.35862 7.79456 5.52991 6.92744 5.86402 6.11826C6.19813 5.30907 6.68849 4.5737 7.30706 3.9542C7.92563 3.33471 8.66027 2.84324 9.46895 2.50792C10.2776 2.1726 11.1445 2 12.0199 2C15.6799 2 18.6799 5 18.6799 8.66V10.76C18.6799 10.94 18.6999 11.14 18.7299 11.35"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        id="Vector_2"
        d="M15.3299 18.8201C15.3299 20.6501 13.8299 22.1501 11.9999 22.1501C11.0899 22.1501 10.2499 21.7701 9.64992 21.1701C9.04992 20.5701 8.66992 19.7301 8.66992 18.8201"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
  </svg>
);

export const ByeHand = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
  >
    <g fill="none" stroke="#000" strokeLinecap="round" strokeWidth={4}>
      <path d="M34.9998 26.6138L15.146 7.31381C13.9866 6.18672 12.133 6.21294 11.0059 7.37237C10.965 7.41445 10.9254 7.45775 10.8871 7.50222C9.74412 8.82893 9.82256 10.8138 11.0666 12.0462L21.0908 21.9762"></path>
      <path
        strokeLinejoin="round"
        d="M21.0909 21.9761L10.1773 11.1547C8.88352 9.87182 6.8201 9.80162 5.44214 10.9936C4.17554 12.0892 4.03694 14.0041 5.13256 15.2707C5.17411 15.3188 5.21715 15.3655 5.26164 15.4108L16.2553 26.6137"
      ></path>
      <path d="M16.2551 26.6137L9.99976 20.5C8.73742 19.2102 6.67293 19.1744 5.36657 20.4195C4.0742 21.6514 4.02513 23.6977 5.25697 24.99C5.26264 24.996 5.26833 25.0019 5.27405 25.0078C14.5036 34.5442 19.2938 39.0485 21.0908 40.5534C23.9998 42.9896 29.7348 44 32.7302 42C35.7257 40 38.4331 37.1544 39.7181 34.3287C40.4831 32.6464 41.9689 27.4595 44.1756 18.7678C44.6248 16.9986 43.5547 15.2001 41.7854 14.7509C41.7625 14.7451 41.7395 14.7395 41.7164 14.7342C39.8832 14.3104 38.0429 15.4115 37.5496 17.2273L34.9998 26.6137"></path>
      <path d="M31.7156 12.6658C31.0037 11.6024 30.19 10.613 29.2884 9.71137C28.3868 8.80978 27.3973 7.99606 26.334 7.28417C25.8048 6.92996 25.2574 6.60096 24.6934 6.29889C24.1327 5.99858 23.5557 5.72488 22.9639 5.47949"></path>
      <path d="M5.19397 33.7761C5.84923 34.8753 6.61005 35.906 7.46322 36.8536C8.31639 37.8011 9.26192 38.6655 10.2866 39.4321C10.7964 39.8135 11.3259 40.1707 11.8733 40.5019C12.4175 40.8311 12.9795 41.1346 13.5576 41.4106"></path>
    </g>
  </svg>
);
