// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagesheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.pagesheader h3 {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 900;
  gap: 10px;
  margin: 0px;
  color: #62008a !important;
  font-family: 'FLTB', sans-serif;
}

h3 svg {
  color: black;
  stroke-width: 2.011px;
  stroke: #000;
  width: 12.44px;
  height: 15.126px;
  flex-shrink: 0;
}

.spinner-text .title {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/PagesHeader/pagesheader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,WAAW;EACX,yBAAyB;EACzB,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".pagesheader {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px;\n}\n\n.pagesheader h3 {\n  display: flex;\n  align-items: center;\n  font-size: 15px;\n  font-weight: 900;\n  gap: 10px;\n  margin: 0px;\n  color: #62008a !important;\n  font-family: 'FLTB', sans-serif;\n}\n\nh3 svg {\n  color: black;\n  stroke-width: 2.011px;\n  stroke: #000;\n  width: 12.44px;\n  height: 15.126px;\n  flex-shrink: 0;\n}\n\n.spinner-text .title {\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
