import React, { useEffect, useState } from "react";
import Slider from "./Slider/Slider";
import HomeCategories from "./HomeCategories/HomeCategories";
import HotOffers from "./HotOffers/HotOffers";
import Header from "../header";
import "./home.css";
import HomeHeader from "./HomeHeader/HomeHeader";
import axios from "axios";
import HomeSearch from "./HomeSearch/HomeSearch";
import PagesButtons from "../PagesButtons/PagesButtons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "../Modal/Modal";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [sitedata, setsitedata] = useState({});
  const [searhcValue, setSearchValue] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [userData, setUserData] = useState(
    localStorage.getItem("manjamUser")
      ? JSON.parse(localStorage.getItem("manjamUser"))
      : null
  );

  function handleGetAllOffers() {
    const data_send = {
      type: "user",
      user_id: userData?.userId,
    };
    axios
      .post("https://api.manjam.shop/v4/offers/select_offers", data_send)
      .then((res) => console.log(res));
  }

  useEffect(() => {
    handleGetAllOffers();
  }, []);

  const getsitedata = () => {
    axios
      .get("https://api.manjam.shop/v4/site/info/getAll")
      .then((res) => {
        // console.log(res.data?.message);
        setsitedata(res?.data?.message[0]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getsitedata();
  }, []);

  const language = useSelector((state) => state.language.lang);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [searchParams] = useSearchParams();
  return (
    <>
      <Modal
        style={{ margin: "auto" }}
        size="350px"
        headerIcon="X"
        show={showModal}
        title={language == "ar" ? "نبذه عن منجم" : "About Mangam"}
        onClose={() => setShowModal(false)}
      >
        <p>{language == "ar" ? sitedata.about_ar : sitedata.about}</p>
      </Modal>
      <div className="home_page" style={{ marginBottom: "80px" }}>
        <div className="headerhome" style={{ flexDirection: "column" }}>
          {/* <Header/> */}
          <HomeHeader />
          <div className="headerbtns">
            <div className="button_text" onClick={() => navigate("/profile")}>
              <div className="text" style={{ color: "white" }}>
                {language == "ar" ? "الملف الشخصي" : "Profile"}
              </div>
            </div>
            <div className="button_text" onClick={() => navigate("/location")}>
              <div className="text" style={{ color: "white" }}>
                {language == "ar" ? "العناوين" : "Addressess"}
              </div>
            </div>
            <div
              className="button_text"
              onClick={() => navigate("/orderlogs2")}
            >
              <div className="text" style={{ color: "white" }}>
                {language == "ar" ? "طلباتي" : "Orders"}
              </div>
            </div>
            <select
              name=""
              id=""
              onChange={(e) => {
                const selectedLang = e.target.value;
                searchParams.set("lang", selectedLang);
                navigate({ search: searchParams.toString() });
                window.location.reload();
              }}
              value={searchParams.get("lang")}
            >
              <option value="en">En</option>
              <option value="ar">Ar</option>
            </select>
            {/* <select
              name=""
              id=""
              onChange={(e) => {
                localStorage.setItem("store", e.target.value);
                window.location.reload();
              }}
              value={localStorage.getItem("store")}
            >
              <option value="ksa">KSA</option>
              <option value="uae">UAE</option>
            </select> */}
          </div>
        </div>
        <HomeSearch
          setCategoryData={setCategoryData}
          categoryData={categoryData}
          setSearchValue={setSearchValue}
          searhcValue={searhcValue}
        />
        <Slider />
        {/* <HomeCategories setCategoryData={setCategoryData} /> */}

        <div className="homeVideo">
          <p>
            <b>How to get in tender ?</b>
          </p>
          <video
            src={require("./12487206_1920_1080_50fps.mp4")}
            style={{ 
              height: "200px",
              width: "95%",
              margin: "auto",
              borderRadius: "10px",
            }}
            controls
            autoplay
            muted
            loop
          ></video>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            setFilters({
              brand:
                e.target.brand.value && e.target.brand.value?.length
                  ? e.target.brand.value
                  : null,
              category:
                e.target.category.value == "All"
                  ? null
                  : e.target.category.value,
            });
          }}
          className="filterform"
        >
          <input type="text" name="brand" id="brand" />
          {categoryData && categoryData?.length ? (
            <select name="category" id="category">
              <option value={null}>{language == "ar" ? "الكل" : "All"}</option>
              {categoryData?.map((item) => {
                return (
                  <option value={item?.id}>
                    {language == "ar" ? item?.title_ar : item?.title}
                  </option>
                );
              })}
            </select>
          ) : null}
          <button className="btn btn-primary">
            {language == "ar" ? "بحث" : "Search"}
          </button>
        </form>
        <HotOffers filters={filters} />

        <PagesButtons language={language} navigate={navigate} />

        <div className="home-deals">
          <p>
            {" "}
            {language == "ar" ? "اقرأ عن عروض منجم" : "Read about Manjam deals"}
          </p>
          <button onClick={() => setShowModal(true)}>
            {language == "ar" ? "اضغط هنا" : "Click Here"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Home;
