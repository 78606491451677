// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countDown{
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}


.countDown h1{
  padding: 10px;
  font-size: 10px;
  border-radius: 3px;
  background-color: #f38c25;
  line-height: normal;
  color: #FFF !important;
  letter-spacing: 1px;
}

.countDown span{
  font-size: 10px;
  font-weight: bolder;
  font-size: 25px;
  margin: 0 5px;
  line-height: normal;

  color: #f38c25;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomHooks/countDownTimer/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB;;;AAGA;EACE,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,mBAAmB;;EAEnB,cAAc;AAChB","sourcesContent":[".countDown{\n  font-size: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 10px 0;\n}\n\n\n.countDown h1{\n  padding: 10px;\n  font-size: 10px;\n  border-radius: 3px;\n  background-color: #f38c25;\n  line-height: normal;\n  color: #FFF !important;\n  letter-spacing: 1px;\n}\n\n.countDown span{\n  font-size: 10px;\n  font-weight: bolder;\n  font-size: 25px;\n  margin: 0 5px;\n  line-height: normal;\n\n  color: #f38c25;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
