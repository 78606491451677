import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "../App.css";
import axios from "axios";
import Modal from "../../Modal/Modal";
import AddForm from "../../AddForm/AddForm";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Switch from "react-switch";

export default function Location() {
  const language = useSelector((state) => state.language.lang);
  const navigate = useNavigate();
  const [defaultBtn, setDefaultBtn] = useState(false);
  const [locations, setLocations] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [formdata, setFormData] = useState({
    city: "",
    country: "",
    location: "",
    name: "",
    phone: "",
    isMain: false,
    user_id: "",
  });

  useEffect(() => {
    console.log(language);
  }, [language]);
  const manjamUser = localStorage.getItem("manjamUser")
    ? JSON.parse(localStorage.getItem("manjamUser"))
    : null;

  function handleGetLocations() {
    if (!manjamUser) {
      toast.warn(language == "ar" ? "قم بتسجيل الدخول أولا" : "Log in first");
      navigate("");
      return;
    }
    axios
      .post("https://api.manjam.shop/v4/user/getAddress", {
        user_id: manjamUser?.userId,
      })
      .then((res) => {
        console.log(res);
        if (res.data.message.length > 0) {
          setLocations(res.data.message);
        } else {
          toast.error("حدث خطأ ما" || res.data.message);
        }
      });
  }

  function handleAddLocation(formData) {
    if (manjamUser == null) {
      toast.warn(language == "ar" ? "قم بتسجيل الدخول" : "Please Log in first");
      return;
    }

    const data_send = {
      ...formData,
      user_id: manjamUser?.userId,
    };
    axios
      .post(`https://api.manjam.shop/v4/user/addAddress`, data_send)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message);
          handleGetLocations();
          setShowAddModal(false);
          setFormData({
            city: "",
            country: "",
            location: "",
            name: "",
            phone: "",
            isMain: false,
            user_id: "",
          });
        } else {
          toast.error(res.data.message || "حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setShowAddModal(false));
  }

  function handleDeleteLocation() {
    if (manjamUser == null) {
      toast.warn(
        language == "ar" ? "قم بتسجيل الدخول أولا" : "please log in first"
      );
      return;
    }
    const data_send = {
      id: rowData?.id,
      user_id: rowData?.user_id,
    };
    axios
      .post("https://api.manjam.shop/v4/user/deleteAddress", data_send)
      .then((res) => {
        toast.info(res.data.message);
        handleGetLocations();
        setShowDelModal(false);
        setFormData({
          city: "",
          country: "",
          location: "",
          name: "",
          phone: "",
          isMain: false,
          user_id: "",
        });
      });
  }

  function handleEditLocation(e) {
    e.preventDefault();
    
    const data_send = {
      ...rowData,
      user_id: manjamUser?.userId,
    };
    axios
      .post(`https://api.manjam.shop/v4/user/updateAddress`, data_send)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message);
          handleGetLocations();
          setShowAddModal(false);
          setFormData({
            city: "",
            country: "",
            location: "",
            name: "",
            phone: "",
            isMain: false,
            user_id: "",
          });
        } else {
          toast.error(res.data.message || "حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setShowAddModal(false));
    console.log();
  }

  useEffect(() => {
    handleGetLocations();
  }, []);

  return (
    <>
      <div className="location-contain d-flex flex-column">
        <Modal
          show={showAddModal}
          cancelButton={() => setShowAddModal(false)}
          title={language == "ar" ? "إضافة عنوان" : "Add Location"}
          showCloseBtn={true}
          onClose={() => setShowAddModal(false)}
          style={{ overFlow: "auto", margin: "10px 0px" }}
          size={"350px"}
        >
          <AddForm handleAddLocation={handleAddLocation} />
        </Modal>

        <Modal
          show={showEditModal}
          title={language == "ar" ? "تعديل العنوان" : "Edit Location"}
          showCloseBtn={true}
          onClose={() => setShowEditModal(false)}
        >
          <form onSubmit={handleEditLocation}>
            <div className="form-group">
              <label>{language == "ar" ? "المدينه" : "City"}</label>
              <input
                type="text"
                value={rowData?.city}
                onChange={(e) =>
                  setRowData({ ...rowData, city: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>{language == "ar" ? "الدولة" : "Country"}</label>
              <input
                type="text"
                value={rowData?.country}
                onChange={(e) =>
                  setRowData({ ...rowData, country: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>{language == "ar" ? "العنوان" : "location"}</label>
              <input
                type="text"
                value={rowData?.location}
                onChange={(e) =>
                  setRowData({ ...rowData, location: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>{language == "ar" ? "الاسم" : "Name"}</label>
              <input
                type="text"
                value={rowData?.name}
                onChange={(e) =>
                  setRowData({ ...rowData, name: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>{language == "ar" ? "رقم الهاتف" : "Phone"}</label>
              <input
                type="tel"
                value={rowData?.phone}
                onChange={(e) =>
                  setRowData({ ...rowData, phone: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label>{language == "ar" ? "أساسي" : "isMain"}</label>
              <Switch
                checked={rowData.isMain == "1" ? true : false}
                onChange={(checked) =>
                  setRowData({ ...rowData, isMain: checked })
                }
              />
            </div>

            <button className="btn btn-primary">
              {language == "ar" ? "تعديل" : "Edit"}
            </button>
          </form>
        </Modal>

        <Modal
          show={showDelModal}
          onClose={() => setShowDelModal(false)}
          showCloseBtn={true}
        >
          <h5>
            {language == "ar"
              ? "هل تريد حذف العنوان؟"
              : "Do you want to delete the location?"}
          </h5>
          <button className="btn btn-danger" onClick={handleDeleteLocation}>
            {language == "ar" ? "تأكيد" : "Confirm"}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setShowDelModal(false)}
          >
            {language == "ar" ? "إلغاء" : "Cancel"}
          </button>
        </Modal>

        <div className="d-flex justify-content-center">
          <h4 className="fw-bold">
            {language == "ar" ? "مساء الخير" : " Good Evening"}{" "}
            <span className="locationText">
              {language == "ar" ? "يا سيد" : "Sir"}
            </span>{" "}
          </h4>
          <i class="fa-solid fa-hands-clapping"></i>
        </div>
        <div>
          <div className="locationText d-flex justify-content-between align-items-center h6 p-3 mb-3 fw-bold">
            <p>{language == "ar" ? "عنوايني" : "My Locations"}</p>
            <button
              className="btn btn-primary"
              onClick={() => setShowAddModal(true)}
            >
              {language == "ar" ? "إضافة" : "Add"}
            </button>
          </div>
        </div>

        {locations.map((location) => (
          <div className="locationCard mx-4 bg-light rounded-4 p-3  my-2">
            <div className="d-flex gap-5">
              <button
                className="deleteBtn"
                style={{zIndex:"122837"}}
                onClick={() => {
                  // alert("sd")
                  setRowData(location);
                  setShowDelModal(true);
                }}
              >
                <i class="fa-solid fa-trash"></i>
              </button>

              <button
                style={{zIndex:"122837"}}
                className="editBtn"
                onClick={() => {
                  setRowData(location);
                  setShowEditModal(true);
                }}
              >
                <i class="fa-solid fa-file"></i>
              </button>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {location?.isMain == 1 ? (
                <button className="btn btn-primary w-25 btn-sm rounded-5">
                  {language == "ar" ? "افتراضي" : "Default"}
                </button>
              ) : (
                <button className="btn btn-outline-warning w-auto btn-sm rounded-5">
                  {language == "ar" ? "اجعلها افتراضي" : "Make Default"}
                </button>
              )}
              {/* <button className="btn btn-primary w-auto btn-sm rounded-5">
              {location?.isMain ? "Default" : ""}
            </button> */}
              <div className="mt-3">
                <h5 className="fw-bold">{location?.country}</h5>
                <span className="textLight">{location?.location}</span>
              </div>
            </div>
          </div>
        ))}

        {/* <div> */}
        {/* <div className="btn btn-primary m-auto">Add New</div> */}
        {/* </div> */}
      </div>
    </>
  );
}
