// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageTitle {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 19px;
  }
  
  .pageTitle svg {
    font-size: 22px;
  }
  
  .pageTitle a,
  .pageTitle h3 {
    color: black;
    font-size: 15px;
    text-decoration: none;
    margin-bottom: 0;

  }

  .checkout{
    padding:  30px 35px 30px 26px;
  }`, "",{"version":3,"sources":["webpack://./src/components/brudCrumbs/brudCrumbs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;;EAEA;;IAEE,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,gBAAgB;;EAElB;;EAEA;IACE,6BAA6B;EAC/B","sourcesContent":[".pageTitle {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    margin-bottom: 19px;\n  }\n  \n  .pageTitle svg {\n    font-size: 22px;\n  }\n  \n  .pageTitle a,\n  .pageTitle h3 {\n    color: black;\n    font-size: 15px;\n    text-decoration: none;\n    margin-bottom: 0;\n\n  }\n\n  .checkout{\n    padding:  30px 35px 30px 26px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
