import React, { useEffect, useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import "./style.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const HomeSearch = ({
  setSearchValue,
  searhcValue,
  setCategoryData,
  categoryData,
}) => {
  const navigate = useNavigate();
  const language = useSelector((state) => state.language.lang);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredHotOffers, setFilteredHotOffers] = useState([]);
  const [products, setProducts] = useState([]);
  const [userData, setUserData] = useState(
    localStorage.getItem("manjamUser")
      ? JSON.parse(localStorage.getItem("manjamUser"))
      : null
  );

  const getCategories = async () => {
    const cats = await axios
      .get("https://api.manjam.shop/v4/category/getAll?type=user", {
        timeout: 8989898989,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          console.log(res.data.message);
          setCategoryData(res.data.message);
        } else {
          toast.error(res.data.message || "حدث خطأ ما!");
        }
      })
      .catch((err) => console.log(err));
    // console.log(cats)
    if (Array.isArray(cats?.data?.message))
      setCategoryData(cats?.data?.message);
  };

  const getOffers = async () => {
    const data_send = {
      type: "user",
      user_id: userData?.userId,
    };
    await axios
      .post("https://api.manjam.shop/v4/offers/select_offers", data_send)

      .then((res) => {
        console.log(res.data.message);
        if (Array.isArray(res.data.message)) {
          // console.log(res?.data?.message);
          const storeName = parseInt(userData?.storeId) == 2 ? "ksa" : "uae";
          setProducts(
            res?.data?.message.filter(
              (item) =>
                item?.store?.filter(
                  (item) => item == storeName
                )?.length
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleSearch() {
    console.log(categoryData, searhcValue);

    if (searhcValue.length > 0) {
      // Filter categories based on search value
      const filtered = categoryData.filter(
        (data) =>
          data?.title_ar?.includes(searhcValue) ||
          data?.title?.toLowerCase()?.includes(searhcValue?.toLowerCase())
      );

      // Filter products inside offers based on search value
      const filteredOffers = products
        .map((offer) => {
          // Filter the products inside the offer
          const filteredProducts = offer.products.filter(
            (item) =>
              item?.title
                ?.toLowerCase()
                ?.includes(searhcValue?.toLowerCase()) ||
              item?.title_ar?.includes(searhcValue)
          );

          // If there are any matching products, return the offer with the filtered products
          if (filteredProducts.length > 0) {
            return { ...offer, products: filteredProducts };
          }
          return null; // Skip if no matching products
        })
        .filter((offer) => offer !== null); // Remove null values (offers with no matching products)

      console.log(filteredOffers);
      setFilteredData(filtered);
      setFilteredHotOffers(filteredOffers);
    } else {
      setFilteredData([]);
      setFilteredHotOffers([]);
    }
  }

  //   function handleSearch() {
  //     console.log(categoryData , searhcValue);
  //     if(searhcValue.length > 0) {
  //       const filtered = categoryData.filter(data =>
  //         data?.title_ar?.includes(searhcValue) || data?.title?.toLowerCase()?.includes(searhcValue?.toLowerCase())
  //       );

  //       const filteredOffers =products.filter(data => data?.products?.filter(item => item?.title?.toLowerCase()?.includes(searhcValue?.toLowerCase()) || item?.title_ar?.includes(searhcValue) ))
  //       console.log(filteredOffers)
  //       setFilteredData(filtered);
  //       setFilteredHotOffers(filteredOffers)
  //     }
  //     else {
  //       setFilteredData([]);
  //       setFilteredHotOffers([]);
  //     }
  //         // Update the filteredData state with the filtered results
  //  }

  useEffect(() => {
    handleSearch();
  }, [searhcValue]);

  useEffect(() => {
    console.log(products);
  }, [products]);

  useEffect(() => {
    getOffers();
    getCategories();
  }, []);

  return (
    <div className="search_container">
      <div
        className="search"
        style={
          filteredData && filteredData.length
            ? { borderRadius: "30px" }
            : { borderRadius: "99px" }
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3px",
            marginLeft: "10px",
          }}
        >
          <div className="search_icon">
            <IoSearchSharp />
          </div>
          <input
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={
              language == "ar" ? "تبحث عن شئ" : "Looking For Something"
            }
          />
        </div>

        {filteredHotOffers && filteredHotOffers.length
          ? filteredHotOffers.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    navigate("/productdetails?offer_id=" + item?.id, {
                      state: {
                        id: item?.products[0]?.id,
                        offer: item,
                      },
                    });
                  }}
                  className="home_offer"
                  style={{ margin: "20px" }}
                >
                  <h5 style={{ margin: "10px 0px" }}>Tenders</h5>
                  <div className="home_category" style={{ padding: "10px" }}>
                    {item?.products?.map((item) => (
                      <p>{item?.title}</p>
                    ))}
                  </div>
                  {/* <h5>{language == "ar" ? item?.products[0]?.title_ar : item?.products[0]?.title}</h5> */}
                </div>
              );
            })
          : null}
        {/* <div className="home_categories_content_search"> */}
        {filteredData && filteredData.length
          ? filteredData.map((item, index) => {
              if (index < 4) {
                return (
                  <div style={{ margin: "20px " }}>
                    <h5 style={{ margin: "10px 0px" }}>Categories</h5>

                    <div
                      style={{ cursor: "pointer" }}
                      className="home_category"
                      onClick={() =>
                        navigate("/categoryproduct", {
                          state: {
                            categoryproduct: item.id,
                            name: item.title,
                            name_ar: item.title_ar,
                          },
                        })
                      }
                    >
                      <div className="home_category_img">
                        <img src={item.image} alt="" />
                      </div>
                      <h6>
                        {language == "ar" ? item.title_ar || "" : item.title}
                      </h6>
                    </div>
                  </div>
                );
              } else return null;
            })
          : null}
        {/* </div> */}
      </div>
    </div>
  );
};

export default HomeSearch;
