// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.currntpage {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;
  /* margin-top: 30px; */
}

.currntpage>* {
  margin: 0px;
  font-weight: 700;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomHooks/CurrentPage/currentpage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".currntpage {\n  display: flex;\n  align-items: center;\n  margin-bottom: 30px;\n  gap: 10px;\n  /* margin-top: 30px; */\n}\n\n.currntpage>* {\n  margin: 0px;\n  font-weight: 700;\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
