// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    gap: 5px;
    margin: 10px 0px;
}

.form-group label {
    font-size: 15px;
    color: black;
}


.form-group input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/AddForm/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,QAAQ;IACR,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;;AAGA;IACI,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".form-group {\n    display: flex;\n    /* align-items: center; */\n    flex-direction: column;\n    gap: 5px;\n    margin: 10px 0px;\n}\n\n.form-group label {\n    font-size: 15px;\n    color: black;\n}\n\n\n.form-group input {\n    width: 100%;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    padding: 10px 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
